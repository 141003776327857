<div class="card-deck table-wrapper-scroll-y my-custom-scrollbar animated fadeIn slow pb-4 pl-5 pl-sm-5 pl-md-4 pl-lg-5 pl-xl-5">

  <!-- Listado de eventos -->

  <div>
    <h1 id="eventoTitulo" class="border-bottom pb-2 mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'navbar.agenda' | translate }}</h1>
    <div id="eventos" *ngFor="let evento of eventos | async" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <div class="card-body">
          <h3 class="card-title"><ins>{{evento.name}}</ins></h3>
          <p class="card-text mb-3">{{evento.descripcion}}</p>
          <p class="card-text mb-2"><i class="bi bi-calendar-event"></i>{{evento.date | date: 'dd/MM/yyyy'}}</p>
          <p class="card-text mb-2"><i class="bi bi-alarm"></i>{{evento.hour}}</p>
          <p class="card-text mb-2"><i class="bi bi-geo-alt-fill"></i><a href="{{evento.link_place}}" target="_blank">{{evento.place}}</a></p>
          <p class="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
            <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
            <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
          </svg>{{evento.price}} €</p>
          <p *ngIf="evento.link_external" class="card-text"><i class="bi bi-info-circle"></i><a href="{{evento.link_external}}" target="_blank">{{ 'agenda.info' | translate }}</a></p>
        </div>

        <div *ngIf="evento.link_shop" class="card-footer">
          <p><i class="bi bi-upc-scan"></i><a href="{{evento.link_shop}}" target="_blank"><strong>{{ 'agenda.comprar' | translate }}</strong></a></p>
        </div>
    </div>
  </div>

</div>
