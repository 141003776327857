
    <div class="page-wrapper">
 <!-- HEADER MOBILE-->
 <header class="header-mobile d-block d-lg-none">
  <div class="header-mobile__bar">
    <div class="container-fluid">
        <div class="header-mobile-inner">
          <div class="logo">
            <img src="assets/CoolAdmin-master/images/icon/logo.png" alt="Admin"/>
          </div>
            <button class="hamburger hamburger--slider" type="button">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
  </div>
    <nav class="navbar-mobile">
        <div class="container-fluid">
            <ul class="navbar-mobile__list list-unstyled">
              <li>
                <a class="js-arrow" [routerLink]="'/register'">
                  <i class="bi bi-house-fill"></i>Home</a>
              </li>
              <li>
                <a [routerLink]="'/register'">
                  <i class="bi bi-card-list"></i>Register</a>
              </li>
              <li>
                <a [routerLink]="'/login'">
                  <i class="bi bi-box-arrow-right"></i>Login</a>
              </li>
            </ul>
        </div>
    </nav>
</header>
<!-- END HEADER MOBILE-->

      <!-- MENU SIDEBAR-->
      <aside class="menu-sidebar d-none d-lg-block">
        <div class="logo">
          <img src="assets/CoolAdmin-master/images/icon/logo.png" alt="ITAcademy"/>
        </div>
          <div class="menu-sidebar__content js-scrollbar1">
              <nav class="navbar-sidebar">
                  <ul class="list-unstyled navbar__list">
                      <li>
                        <a class="js-arrow" [routerLink]="'/register'">
                          <i class="bi bi-house-fill"></i>Home</a>
                      </li>
                      <li>
                        <a [routerLink]="'/register'">
                          <i class="bi bi-card-list"></i>Register</a>
                      </li>
                      <li>
                        <a [routerLink]="'/login'">
                          <i class="bi bi-box-arrow-right"></i>Login</a>
                      </li>
                  </ul>
              </nav>
          </div>
      </aside>
      <!-- END MENU SIDEBAR-->

      <!-- PAGE CONTAINER-->
      <div class="page-container">
          <!-- HEADER DESKTOP-->
          <header class="header-desktop">
              <div class="section__content section__content--p30">
                  <div class="container-fluid">
                      <div class="header-wrap">

                      </div>
                  </div>
              </div>

          </header>
          <!-- HEADER DESKTOP-->

          <!-- MAIN CONTENT-->
          <div class="main-content container">
            <div class="page-wrapper">
              <div class="page-content--bge5">
                  <div class="container">
                      <div class="login-wrap">
                          <div class="login-content">
                              <h4 class="login-logo">
                                  Create new account
                              </h4>
                              <div class="login-form">
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm)"
                                class="login100-form validate-form flex-sb flex-w">

                                      <div class="form-group">
                                          <label>Email</label>
                                          <div class="wrap-input100 m-b-16">
                                            <input class="input100"
                                                   type="email"
                                                   name="email"
                                                   formControlName='email'
                                                   placeholder="Email"
                                                   class="form-control"
                                                   [ngClass]="{'is-invalid': registerForm.get('email')?.touched && registerForm.get('email')?.hasError('required')}">
                                            <span class="focus-input100"></span>
                                            <div class="invalid-feedback">El correo es obligatorio</div>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                          <label>Name</label>
                                          <div class="wrap-input100 m-b-16">
                                            <input class="input100"
                                                   type="text"
                                                   name="nombre"
                                                   formControlName='name'
                                                   placeholder="Nombre y apellidos"
                                                   class="form-control"
                                                   [ngClass]="{'is-invalid': registerForm.get('name')?.touched && registerForm.get('name')?.hasError('required')}">
                                            <span class="focus-input100"></span>
                                            <div class="invalid-feedback">El nombre es obligatorio</div>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                          <label>Password</label>
                                          <div class="wrap-input100 m-b-16" data-validate = "Password is required">
                                            <input class="input100"
                                                   type="password"
                                                   name="password"
                                                   formControlName='password'
                                                   minlength="6"
                                                   placeholder="Password"
                                                   class="form-control"
                                                   [ngClass]="{'is-invalid': registerForm.get('password')?.touched && registerForm.get('password')?.hasError('required')}">
                                            <span class="focus-input100"></span>
                                            <div class="invalid-feedback">El password es obligatorio</div>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <label>Password Confirmation</label>
                                        <div class="wrap-input100 m-b-16" data-validate = "Password is required">
                                          <input class="input100"
                                                 type="password"
                                                 name="password_confirmation"
                                                 formControlName='password_confirmation'
                                                 minlength="8"
                                                 placeholder="Password Confirmation"
                                                 class="form-control"
                                                 [ngClass]="{'is-invalid': registerForm.get('password_confirmation')?.touched && registerForm.get('password_confirmation')?.hasError('required')}">
                                          <span class="focus-input100"></span>
                                          <div class="invalid-feedback">La confirmación del password es obligatoria</div>
                                        </div>
                                    </div>
                                      <button class="mt-4 au-btn au-btn--block au-btn--green m-b-20" type="submit">Create account</button>
                                  </form>
                                  <div class="register-link">
                                    <a routerLink="/login" class="txt1">
                                      ¿Ya tienes cuenta? / Ingresar
                                  </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="copyright">
                                  <p>Copyright © 2018 Colorlib. All rights reserved. Template by <a href="https://colorlib.com">Colorlib</a>.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- END MAIN CONTENT-->
          <!-- END PAGE CONTAINER-->
<!-- end document-->












