<div class="card-deck table-wrapper-scroll-y my-custom-scrollbar animated fadeIn slow pb-4 pl-5 pl-sm-5 pl-md-4 pl-lg-5 pl-xl-5">

  <!-- Listado de Novelas -->
  <div class="mb-5">
    <h1 id="libroTitulo" class="border-bottom mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'genero.novela' | translate }}</h1>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.ninna.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.ninna.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.ninna.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.ninna.premios' | translate }}</strong>
      </div>
    </div>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.notte.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.notte.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.notte.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.notte.premios' | translate }}</strong>
      </div>
    </div>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.letteratura.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.letteratura.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.letteratura.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.letteratura.premios' | translate }}</strong>
      </div>
    </div>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.casa.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.casa.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.casa.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.casa.premios' | translate }}</strong>
      </div>
    </div>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.cuore.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.cuore.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.cuore.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.cuore.premios' | translate }}</strong>
      </div>
    </div>

    <div id="libros" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'libros.infanzia.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'libros.infanzia.titulo' | translate }}</h3>
        <p class="card-text">{{ 'libros.infanzia.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'libros.infanzia.premios' | translate }}</strong>
      </div>
    </div>
  </div>

  <!-- Listado de Teatro -->
  <div class="mb-5">
    <h1 id="teatroTitulo" class="border-bottom mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">Teatro</h1>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'teatro.aucelle.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.aucelle.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.aucelle.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.aucelle.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'teatro.ratas.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.ratas.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.ratas.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.ratas.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{'teatro.marinella.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.marinella.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.marinella.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.marinella.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{'teatro.arbol.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.arbol.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.arbol.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.arbol.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{'teatro.hielo.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.hielo.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.hielo.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.hielo.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{'teatro.quattro.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.quattro.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.quattro.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.quattro.premios' | translate }}</strong>
      </div>
    </div>

    <div id="teatro" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{'teatro.freva.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{ 'teatro.freva.titulo' | translate }}</h3>
        <p class="card-text">{{'teatro.freva.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{'teatro.freva.premios' | translate }}</strong>
      </div>
    </div>

  </div>

  <!-- Listado de colaboraciones -->
  <div class="mb-5">
    <h1 id="compartidoTitulo" class="border-bottom pb-2 mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'genero.colectivos' | translate }}</h1>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.panamericana.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.panamericana.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.panamericana.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.panamericana.premios' | translate }}</strong>
      </div>
    </div>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.mamme.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.mamme.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.mamme.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.mamme.premios' | translate }}</strong>
      </div>
    </div>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.diva.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.diva.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.diva.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.diva.premios' | translate }}</strong>
      </div>
    </div>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.napoli.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.napoli.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.napoli.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.napoli.premios' | translate }}</strong>
      </div>
    </div>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.quello.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.quello.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.quello.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.quello.premios' | translate }}</strong>
      </div>
    </div>

    <div id="compartido" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <img class="card-img-top mt-3" src="{{ 'colectivos.ancora.imagen' | translate }}" alt="">
      <div class="card-body">
        <h3 class="card-title">{{'colectivos.ancora.titulo' | translate }}</h3>
        <p class="card-text">{{ 'colectivos.ancora.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'colectivos.ancora.premios' | translate }}</strong>
      </div>
    </div>

  </div>

  <!-- Listado de Traducciones -->
  <div class="mb-5">

    <h1 id="compartidoTitulo" class="border-bottom pb-2 mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'genero.traducciones' | translate }}</h1>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.racconti.titulo' | translate }} - {{'traducciones.racconti.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.racconti.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.racconti.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.appartamento.titulo' | translate }} - {{'traducciones.appartamento.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.appartamento.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.appartamento.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.629.titulo' | translate }} - {{'traducciones.629.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.629.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.629.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.testa.titulo' | translate }} - {{'traducciones.testa.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.testa.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.testa.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.nave.titulo' | translate }} - {{'traducciones.nave.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.nave.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.nave.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.historia.titulo' | translate }} - {{'traducciones.historia.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.historia.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.historia.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.figlio.titulo' | translate }} - {{'traducciones.figlio.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.figlio.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.figlio.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.todo.titulo' | translate }} - {{'traducciones.todo.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.todo.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.todo.premios' | translate }}</strong>
      </div>
    </div>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">
      <div class="card-body">
        <h3 class="card-title">{{'traducciones.capodanno.titulo' | translate }} - {{'traducciones.capodanno.autor' | translate }}</h3>
        <p class="card-text">{{ 'traducciones.capodanno.descripcion' | translate }}</p>
      </div>
      <div class="card-footer">
        <strong>{{ 'traducciones.capodanno.premios' | translate }}</strong>
      </div>
    </div>

  </div>

</div>
