<body>

<nav class="navbar navbar-expand-lg navbar-light bg-transparent">

  <div id="header" class="d-flex mt-2">
    <a href="" [routerLink]="['']"><h1 class="mr-5" id="nombre">Alessio Arena</h1></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>


  <div class="collapse navbar-collapse d-lg-flex d-xl-flex justify-content-lg-end justify-content-xl-end mb-4" id="navbarSupportedContent">
    <nav id="menu" class="nav flex-column flex-md-column flex-lg-row flex-xl-row pl-2">

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/biografia']" routerLinkActive="router-link-active" >{{ 'navbar.biografia' | translate }}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" >
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/libros']" routerLinkActive="router-link-active">{{ 'navbar.libros' | translate }}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/discos']" routerLinkActive="router-link-active" >{{ 'navbar.discos' | translate }}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/videos']" routerLinkActive="router-link-active" >{{ 'navbar.videos' | translate }}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/agenda']" routerLinkActive="router-link-active" >{{ 'navbar.agenda' | translate }}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link ml-2 ml-sm-2 ml-md-4" [routerLink]="['/contacto']" routerLinkActive="router-link-active" >{{ 'navbar.contacto' | translate }}</a>
      </li>

      <li class="nav-item">
        <select class="nav-link ml-3 ml-sm-3 ml-md-5" #langselect (change)="translate.use(langselect.value)">
          <option id="option" *ngFor="let lang of translate.getLangs()" [value]="lang">{{ lang.charAt(0).toUpperCase() + lang.slice(1) }}</option>
        </select>
      </li>

    </nav>
  </div>

</nav>

<router-outlet></router-outlet>

</body>




