



<div class="page-wrapper">
  <!-- HEADER MOBILE-->
  <header class="header-mobile d-block d-lg-none">
      <div class="header-mobile__bar">
          <div class="container-fluid">
              <div class="header-mobile-inner">
                  <a class="logo" href="assets/CoolAdmin-master/index.html">
                      <img src="assets/CoolAdmin-master/images/icon/logo.png" alt="CoolAdmin" />
                  </a>
                  <button class="hamburger hamburger--slider" type="button">
                      <span class="hamburger-box">
                          <span class="hamburger-inner"></span>
                      </span>
                  </button>
              </div>
          </div>
      </div>
      <nav class="navbar-mobile">
          <div class="container-fluid">
              <ul class="navbar-mobile__list list-unstyled">
                  <li class="has-sub">
                      <a class="js-arrow" href="#">
                          <i class="fas fa-tachometer-alt"></i>Dashboard</a>
                  </li>
                  <li>
                      <a href="assets/CoolAdmin-master/table.html">
                          <i class="fas fa-table"></i>Tables</a>
                  </li>
                  <li class="has-sub">
                      <a class="js-arrow" href="#">
                          <i class="fas fa-copy"></i>Pages</a>
                      <ul class="navbar-mobile-sub__list list-unstyled js-sub-list">
                          <li>
                              <a href="assets/CoolAdmin-master/login.html">Login</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/register.html">Register</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/forget-pass.html">Forget Password</a>
                          </li>
                      </ul>
                  </li>
                  <li class="has-sub">
                      <a class="js-arrow" href="#">
                          <i class="fas fa-desktop"></i>UI Elements</a>
                      <ul class="navbar-mobile-sub__list list-unstyled js-sub-list">
                          <li>
                              <a href="assets/CoolAdmin-master/button.html">Button</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/badge.html">Badges</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/tab.html">Tabs</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/card.html">Cards</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/alert.html">Alerts</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/progress-bar.html">Progress Bars</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/modal.html">Modals</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/switch.html">Switchs</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/grid.html">Grids</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/fontawesome.html">Fontawesome Icon</a>
                          </li>
                          <li>
                              <a href="assets/CoolAdmin-master/typo.html">Typography</a>
                          </li>
                      </ul>
                  </li>
              </ul>
          </div>
      </nav>
  </header>
  <!-- END HEADER MOBILE-->

  <!-- MENU SIDEBAR-->
  <aside class="menu-sidebar d-none d-lg-block">
    <div class="logo">
      <img src="assets/images/IT_logo.png" style="height: 40px; width: 170px;" alt="ITAcademy"/>
    </div>
      <div class="menu-sidebar__content js-scrollbar1">
          <nav class="navbar-sidebar">
              <ul class="list-unstyled navbar__list">
                <li>
                  <a class="js-arrow" [routerLink]="'/register'">
                    <i class="bi bi-house-fill"></i>Home</a>
                </li>
                <li>
                  <a [routerLink]="'/register'">
                    <i class="bi bi-card-list"></i>Register</a>
                </li>
                <li>
                  <a [routerLink]="'/login'">
                    <i class="bi bi-box-arrow-right"></i>Login</a>
                </li>
              </ul>
          </nav>
      </div>
  </aside>
  <!-- END MENU SIDEBAR-->

  <!-- PAGE CONTAINER-->
  <div class="page-container">
      <!-- HEADER DESKTOP-->
      <header class="header-desktop">
          <div class="section__content section__content--p30">
              <div class="container-fluid">
                  <div class="header-wrap">
                      <form class="form-header" action="" method="POST">
                          <input class="au-input au-input--xl" type="text" name="search" placeholder="Search for datas &amp; reports..." />
                          <button class="au-btn--submit" type="submit">
                              <i class="zmdi zmdi-search"></i>
                          </button>
                      </form>

                      </div>
                  </div>
              </div>

      </header>
      <!-- HEADER DESKTOP-->

      <!-- MAIN CONTENT-->
      <div class="main-content container">
        <div class="page-wrapper">
          <div class="page-content--bge5">
              <div class="container">
                  <div class="login-wrap">
                      <div class="login-content">
                          <h4 class="login-logo">Forgot your password?</h4>
                          <p>Don't worry! Confirm your email and reset it now!</p>
                          <div class="login-form mt-3">
                            <form [formGroup]="passwordForm"
                                  (ngSubmit)="sendPasswordMail(passwordForm)"
                                  class="login100-form validate-form flex-sb flex-w">
                                  <div class="form-group">
                                      <div class="wrap-input100 m-b-16">
                                        <input class="input100"
                                               type="email"
                                               name="email"
                                               formControlName='email'
                                               placeholder="Email"
                                               class="form-control"
                                              [ngClass]="{'is-invalid': passwordForm.get('email')?.touched && passwordForm.get('email')?.hasError('required')}">
                                        <span class="focus-input100"></span>
                                        <div class="invalid-feedback">El correo es obligatorio</div>
                                    </div>
                                  </div>
                                  <button class="au-btn au-btn--block au-btn--green m-b-20 mt-3" type="submit">Send email</button>
                              </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <!-- END MAIN CONTENT-->
      <!-- END PAGE CONTAINER-->
<!-- end document-->


