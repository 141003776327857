
<div class="card-deck table-wrapper-scroll-y my-custom-scrollbar animated fadeIn slow pb-4 pl-5 pl-sm-5 pl-md-4 pl-lg-5 pl-xl-5 mt-2">
  <h1 id="traduccionTitulo" class="border-bottom mt-3 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'navbar.contacto' | translate }}</h1>

  <div class="row contacto">

    <div id="traducciones" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-5 pb-4">

      <div class="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-telephone-inbound-fill mb-4" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"/>
        </svg>
      </div>

      <div class="d-block letra">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center mx-auto">
          <p><strong>Alessio Arena</strong></p>
          <p>+34 677 000 517</p>
        </div>
      </div>

    </div>


    <div id="traducciones" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center pt-5 pb-4 border-left">

      <div class="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-envelope-fill mb-4" viewBox="0 0 16 16">
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
        </svg>
      </div>

      <div class="letra d-block">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center mx-auto">
          <p><strong>Alessio Arena</strong></p>
          <p>info@alessioarena.com</p>
        </div>
      </div>

    </div>

    <div id="traducciones" class="col-12 redes d-flex text-center pt-5 pb-5 border-top">

      <div class="text-center col-3">
        <a href="https://www.facebook.com/alessioarenaofficial" target="_blank"><img src="/assets/images/facebook.png" width="50" height="50"  alt=""></a>
      </div>

      <div class="text-center col-3">
        <a href="https://www.instagram.com/alessioarenamusica/?hl=es" target="_blank"><img src="/assets/images/instagram.png" width="50" height="50"  alt=""></a>
      </div>

      <div class="text-center col-3">
        <a href="https://www.youtube.com/channel/UCrw3PeP7xckaWKOZAQSNwZQ" target="_blank"><img src="/assets/images/youtube.png" width="50" height="50"  alt=""></a>
      </div>

      <div class="text-center col-3">
        <a href="https://open.spotify.com/artist/4Am44NfcHNR70bBt9j1Kqc?si=q3-uMJORQFaHWSeqZAQPdA&nd=1" target="_blank"><img src="/assets/images/spotify.png" width="50" height="50"  alt=""></a>
      </div>

    </div>

  </div>

</div>





