<div class="card-deck table-wrapper-scroll-y my-custom-scrollbar animated fadeIn slow mt-3 pb-4 pl-5 pl-sm-5 pl-md-4 pl-lg-5 pl-xl-5">

  <!-- Listado de Traducciones -->
  <div>
    <h1 id="traduccionTitulo" class="border-bottom mt-3 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'navbar.biografia' | translate }}</h1>

    <div id="traducciones" class="card border-0 col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 mb-4">

      <p class="mt-3 mb-2 m-r2 ml-2 pt-3">{{ 'biografia.1' | translate }}<strong>{{ 'biografia.2' | translate }}</strong>{{ 'biografia.3' | translate }}</p>

      <p class="mt-3 mb-2 m-r2 ml-2"><strong>{{ 'biografia.4' | translate }}</strong>{{ 'biografia.5' | translate }}</p>

      <p class="mt-3 mb-2 m-r2 ml-2">{{ 'biografia.6' | translate }}</p>

      <p class="mt-3 mb-3 m-r2 ml-2">
        {{ 'biografia.7' | translate }}<strong>{{ 'biografia.8' | translate }}</strong>{{ 'biografia.9' | translate }}<strong>{{ 'biografia.10' | translate }}</strong>{{ 'biografia.11' | translate }}
      </p>
    </div>
  </div>

</div>

