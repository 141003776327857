
    <div class="page-wrapper">
        <!-- HEADER MOBILE-->
        <header class="header-mobile d-block d-lg-none">
            <div class="header-mobile__bar">
                <div class="container-fluid">
                    <div class="header-mobile-inner">
                      <div class="logo">
                        <img src="assets/CoolAdmin-master/images/icon/logo.png" alt="ITAcademy"/>
                      </div>
                        <button class="hamburger hamburger--slider" type="button">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <nav class="navbar-mobile">
                <div class="container-fluid">
                    <ul class="navbar-mobile__list list-unstyled">
                        <li>
                          <form (ngSubmit)="logout()">
                            <button class="text-dark" type="submit"><i class="bi bi-box-arrow-left mr-3"></i>Logout</button>
                          </form>
                      </li>
                    </ul>
                </div>
            </nav>
        </header>
        <!-- END HEADER MOBILE-->

        <!-- MENU SIDEBAR-->
        <aside class="menu-sidebar d-none d-lg-block">
            <div class="logo">
              <img src="assets/CoolAdmin-master/images/icon/logo.png" alt="ITAcademy"/>
            </div>
            <div class="menu-sidebar__content js-scrollbar1">
                <nav class="navbar-sidebar">
                    <ul class="list-unstyled navbar__list">
                      <li>
                        <form (ngSubmit)="logout()">
                          <button class="text-dark" type="submit"><i class="bi bi-box-arrow-left mr-3"></i>Logout</button>
                        </form>
                      </li>
                    </ul>
                </nav>
            </div>
        </aside>
        <!-- END MENU SIDEBAR-->

        <!-- PAGE CONTAINER-->
        <div class="page-container">
            <!-- HEADER DESKTOP-->
            <header class="header-desktop">
                <div class="section__content section__content--p30">
                    <div class="container-fluid">
                        <div class="header-wrap d-flex justify-content-end">
                            <div class="account-item clearfix js-item-menu">
                              <div class="image">
                                  <img src="assets/images/alessio_cara.jpg" alt="Alessio Arena" />
                              </div>
                              <div class="content">
                                  {{this.user.name}}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <!-- HEADER DESKTOP-->

            <!-- MAIN CONTENT-->
            <div class="main-content container">
                        <div class="justify-content-center">
                            <div id="table">
                                <h2 class="title-1 text-center mb-4">Listado de Eventos</h2>
                                <div class="d-flex justify-content-center pb-4">
                                  <button [routerLink]="'/eventos/create'" class="btn btn-outline-primary me-5">Crear evento</button>
                                </div>
                                  <table class="table table-responsive table-striped mb-4">
                                    <thead>
                                      <tr class="bg-dark text-light">
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Lugar</th>
                                        <th>Map</th>
                                        <th>Precio</th>
                                        <th>Info</th>
                                        <th>Comprar</th>
                                        <th>Acción</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                      <tr *ngFor="let evento of eventos | async">
                                        <td>{{evento.date}}</td>
                                        <td>{{evento.hour}}</td>
                                        <td>{{evento.name}}</td>
                                        <td>{{evento.descripcion}}</td>
                                        <td>{{evento.place}}</td>
                                        <td>{{evento.link_place}}</td>
                                        <td>{{evento.price}}</td>
                                        <td>{{evento.link_external}}</td>
                                        <td>{{evento.link_shop}}</td>
                                        <td>
                                          <i class="bi bi-pencil-fill m-1 text-primary" [routerLink]="['/eventos/update', evento.id]"></i>
                                          <i class="bi bi-trash-fill m-1 text-danger" (click)="deleteEvento(evento.id)"></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                    </div>
                </div>
            </div>
            <!-- END MAIN CONTENT-->
            <!-- END PAGE CONTAINER-->
        </div>

<!-- end document-->


