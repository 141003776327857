<div class="card-deck table-wrapper-scroll-y my-custom-scrollbar animated fadeIn slow mt-3 pb-4 pl-5 pl-sm-5 pl-md-4 pl-lg-5 pl-xl-5">

  <!-- Listado de Discos solo -->
  <div class="mb-5">
    <h1 id="libroTitulo" class="border-bottom pt-2 ml-1 ml-sm-2 ml-md-5 ml-lg-5 ml-xl-5 col-4 col-sm-5 col-md-8 col-lg-10 col-xl-10">{{ 'titulos.videos' | translate}}</h1>

  <div class="layout">
    <div id="wrap" class="row">

      <div id="libros" class="row ml-2" >
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top" width="560" height="315" src="https://www.youtube.com/embed/ui0xabzDkGo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Ninna nanna delle mosche</h3>
        </div>
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top" width="560" height="315" src="https://www.youtube.com/embed/nrbHGKledV0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Il paese che non c'era</h3>
        </div>
      </div>

      <div id="libros" class="row ml-2" >
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/GZ0KvEPD2tg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">El hombre que quiso ser canción (con Miguel Poveda)</h3>
        </div>
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top" width="560" height="315" src="https://www.youtube.com/embed/-mPfOOzrIcM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Diablada</h3>
        </div>
      </div>

      <div id="libros" class="row ml-2" >
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/nhA1QddTvXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Atacama (con Manuel García)</h3>
        </div>
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/BPf0gR6ZU_Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Amor circular (con Marta Gómez)</h3>
        </div>
      </div>

      <div id="libros" class="row ml-2" >
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/JDlksA8ARQ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">'Ndifferenza </h3>
        </div>
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/G8sQJp6q0yE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Tutto quello che so dei satelliti di Urano</h3>
        </div>
      </div>

      <div id="libros" class="row ml-2" >
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/1EFaWNXf_eI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Una dona que es deia Muntanya (con Elena Gadel)</h3>
        </div>
        <div class="card-body col-10 border border-top-0 col-sm-10 col-md-10 col-lg-6 col-xl-6">
          <iframe class="card-img-top"  width="560" height="315" src="https://www.youtube.com/embed/bOYxKkXrzVE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h3 class="card-title mt-2">Los ojos (Directo en TV2)</h3>
        </div>
      </div>

    </div>
  </div>

</div>
